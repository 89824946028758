import { template as template_707394179e1f40c28011f5648e25f942 } from "@ember/template-compiler";
const SidebarSectionMessage = template_707394179e1f40c28011f5648e25f942(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
