import { template as template_112854c610494f02805d4b224d9aad14 } from "@ember/template-compiler";
const FKLabel = template_112854c610494f02805d4b224d9aad14(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
